<template>
  <li>
    <div class="emcee_avatar">
      <img :src="emceeObj.avatar" /><span class="live_link_btn"></span>
    </div>
    <div class="emcee_info">
      <div class="emcee_h1 display-box">
        <section class="flex">{{ emceeObj.name }}</section>
        <section>{{ emceeObj.hobby }}</section>
      </div>
      <p>{{ emceeObj.old }}/在线</p>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    emceeObj: Object,
  },
  methods: {},
};
</script>
