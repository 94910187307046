import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/landing1.html",
    name: "Home",
    meta: {title: '微麦交友'},
    component: Home,
  },
  {
    path: "/www/ivp/v4/module/17dating/17dating.html",
    name: "Home",
    meta: {title: '微麦交友'},
    component: Home,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
