<script>
  var UA = {};
  var ua = navigator.userAgent.toLowerCase(),s;
  UA.ie =  (s = ua.match(/msie ([\d.]+)/))?s[1]:false;
  UA.firefox = (s =ua.match(/firefox\/([\d.]+)/))?!!s[1]:false;
  UA.chrome = (s = ua.match(/chrome\/([\d.]+)/))?!!s[1]:false;
  UA.opera = (s = ua.match(/opera.([\d.]+)/))?!!s[1]:false;
  UA.safari =  (s = ua.match(/version\/([\d.]+).*safari/))?!!s[1]:false;
  UA.android =  (s=ua.match(/android/))?!!s:false;
  UA.iphone =  (s=ua.match(/iphone os/))?!!s:false;
  UA.ipad =  (s=ua.match(/ipad/))?!!s:false;
  UA.ios = UA.ipad || UA.iphone;
  UA.isWin32 = /win32/i.test(window.navigator.platform); 
  UA.weixin = (s=ua.match(/micromessenger/))?!!s:false;

  export default {
    UA
  }
</script>