<template>
  <div v-if="sysdata.wx">
    <div v-if="sysdata.ios" style="position:fixed; top:0; bottom:0; left:0; right:0; z-index:1000; background-color:#fff;"><img src="//cdnfile.imifun.com/h5/ivp/style/img/wx_iphone_navigator.jpg" width="100%"></div>
    <div v-else style="position:fixed; top:0; bottom:0; left:0; right:0; z-index:1000; background-color:#fff;"><img src="//cdnfile.imifun.com/h5/ivp/style/img/wx_android_navigator.jpg" width="100%"></div>
  </div>
</template>

<script>
export default {
  props: {
    sysdata: Object,
  },
  name: "DownWXMask",
  methods: {},
};
</script>
