<template>
  <div class="home">
    <DownWXMask :sysdata="sysData" v-if="downClickCb"></DownWXMask>
    <div class="top_content1"><img src="@/assets/images/top_p1.png" /></div>
    <div class="emcee_content1">
      <ul class="clear">
        <emceecard
          v-for="emceeObj in emceeList"
          :key="emceeObj.id"
          :emceeObj="emceeObj"
        ></emceecard>
        <!-- <li>
          <div class="emcee_avatar">
            <img src="@/assets/images/avatar1.png" /><span
              class="live_link_btn"
            ></span>
          </div>
          <div class="emcee_info">
            <div class="emcee_h1 display-box">
              <section class="flex">萌萌酱</section>
              <section>喜欢健身</section>
            </div>
            <p>26岁/在线</p>
          </div>
        </li> -->
      </ul>
    </div>
    <div class="footer_content1">
      <div class="footer_down">
        <a href="https://cdnstatic.yiqizhumeng.com/weimai/pre/weimai_2677_64b.apk" class="down_btn" :class="donwBtnCl" v-on:click="donwBtnClick">立即下载app，查看更多异性</a>
      </div>
      <div class="footer_ab_content">
        <div class="display-box ab_hd">
          <section><span class="radio_point"></span></section>
          <section class="flex">
            <p class="f_p1">只需2步骤，就能与附近的陌生异性聊天！</p>
            <p class="f_p2">
              首先：点击下方链接下载“微麦交友”，其次：注册自己的私人账号，最后：10秒匹配有缘异性…，开始聊天
            </p>
          </section>
        </div>
        <div class="display-box ab_hd">
          <section><span class="radio_point"></span></section>
          <section class="flex">
            <p class="f_p1">怎么用交友软件认识附近或者同城的人？</p>
            <p class="f_p2">
              下载微麦交友App，不光可以找到兴趣相同的女生，还可以找到附近的人，感兴趣就来看看吧！
            </p>
          </section>
        </div>
        <div class="display-box ab_hd">
          <section><span class="radio_point"></span></section>
          <section class="flex">
            <p class="f_p1">视频1对1聊天哪个好？</p>
            <p class="f_p2">
              微麦交友一对一聊天时比较常用的聊天软件，视频聊天推荐使用。
            </p>
          </section>
        </div>
        <div class="display-box ab_hd">
          <section><span class="radio_point"></span></section>
          <section class="flex">
            <p class="f_p1">视频聊天软件哪个好用</p>
            <p class="f_p2">心意相投的人，开启你的交友之旅。</p>
          </section>
        </div>
      </div>
    </div>
    <div class="float_mod" :class="floatModCl.floatMod">
      <div class="logo1" :class="floatModCl.logo1">
        <img src="@/assets/images/icon-logo1.png" />
      </div>
      <div class="fm_ot" :class="floatModCl.fmOt">
        <div class="fm_info" :class="floatModCl.fmInfo">
          <p class="fm_p1">微麦交友</p>
          <p class="fm_p2">附近约会，同城交友、聊天</p>
          <p class="fm_p3" :class="floatModCl.fmP3">武汉一起筑梦科技有限公司</p>
        </div>
        <div class="fm_down" :class="floatModCl.fmDown">
          <a href="https://cdnstatic.yiqizhumeng.com/weimai/pre/weimai_2677_64b.apk" class="fm_down_btn" v-on:click="donwBtnClick1">免费下载</a>
        </div>
        <p class="fm_p4" :class="floatModCl.fmP4">
          版本1.1.9；更新与2022/11/22
        </p>
      </div>
    </div>
    <!-- <div class="float_mod clear">
      <div class="logo1 left"><img src="@/assets/images/icon-logo1.png"></div>
      <div class="fm_ot left">
        <div class="fm_info left">
          <p class="fm_p1">微麦交友</p>
          <p class="fm_p2">附近约会，同城交友、聊天</p>
        </div>
        <div class="fm_down right"><span class="fm_down_btn">免费下载</span></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import emceecard from "@/components/emcee_card";
export default {
  data() {
    return {
      emceeList: "",
      floatModCl: {},
      donwBtnCl: '',
      downClickCb: false,
      sysData: {}
    };
  },
  name: "Home",
  components: {
    emceecard,
  },
  mounted() {
    let that = this;

    that.sysData = {
      wx: that.Global.UA.weixin,
      ios: that.Global.UA.ios
    }

    that.$http.get("json/homeData.json").then(function (res) {
      var data = res.data;
      that.emceeList = data.emceeList;
    });

    that.fmcl1 = {};
    that.fmcl2 = {
      floatMod: "clear",
      logo1: "left",
      fmOt: "left",
      fmInfo: "left",
      fmDown: "right",
      fmP3: "hide",
      fmP4: "hide",
    };
    that.floatModCl = that.fmcl1;
    that.fmTimer = setTimeout(function () {
      that.floatModCl = that.fmcl2;
    }, 2e3);
    window.addEventListener("scroll", that.fmScroll);
  },
  methods: {
    fmScroll() {
      let that = this;
      clearTimeout(that.fmTimer);
      that.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (that.scroll > 0) {
        that.floatModCl = that.fmcl2;
      } else {
        that.floatModCl = that.fmcl1;
      }
    },
    donwBtnClick(){
      let that = this;
      that.donwBtnCl = 'down_btn_click';
      if(that.sysData.wx){
        that.downClickCb = true;
      }
    },
    donwBtnClick1(){
      let that = this;
      if(that.sysData.wx){
        that.downClickCb = true;
      }
    }
  },
};
</script>
<style lang="css" src="@/assets/style/home.css"></style>