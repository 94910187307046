import Vue from "vue";
// import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Axios from "axios";
Vue.prototype.$http = Axios;

import Global from "@/components/global";
Vue.prototype.Global = Global;

import DownWXMask from "@/components/down_wx_mask";
Vue.component("DownWXMask", DownWXMask);

Vue.config.productionTip = false;

router.beforeEach((to,from,next) =>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  next();
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
